import React, { Component } from 'react'
import './Footer.css'

export default class Footer extends Component {
  render() {
    return (
      <div className="footer-section" id="contact">
        <div className="e-mail">kenjohnson0704@gmail.com</div>
        <div className="copyright">Copyright © 2020 Ken Johnson</div>
      </div>
    )
  }
}
